.container {
  position: relative;
  --listing-card-carousel-min-width: auto;
  --listing-card-overflow: visible;
  --listing-card-scroll-snap-carousel-image-height: 250px;
  --listing-card-width: 279px;

  @media (--screen-xl) {
    --listing-card-width: 100%;
  }
}
